import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import util from '../../util'
import { colors } from '../../style'
import MediaView from './MediaView'
import AttributesView from './AttributesView'

const Wrapper = styled.div`
    margin-top:20px;
    padding-top:20px;
    border-top:1px solid #cccccc;
`

const Name = styled.div`

`

const UserView = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items: flex-start;
`

const UserInfo = styled.div`
    flex:1;
    h3{
        font-size:30px;
    }
    h4{
        margin-top:20px;
        font-size:20px;
    }
    p{
        margin-top:10px;
    }
`

const CountList = styled.ul`
    display:flex;
    margin-top:10px;
    li{
        margin-right:20px;
        span{
            font-weight:bold;
            font-size:20px;
        }
    }
`

const ProfileImage = styled.div`
    width:100px;
    height:100px;
    border-radius:50px;
    border:4px solid #9999ff;
    overflow:hidden;
    margin-right:20px;
    img{
        width:100%;
        height:100%;
    }
`

const MediaWrapper = styled.div`
    margin-top:20px;
    padding:40px;
    padding-top:20px;
    h2{
        font-size:30px;
        margin-right:20px;
    }
`

const MediaInfo = styled.div`
    display:flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom:30px;
`

const InfluencerView = ({ influencer }) => {

    const visionList = influencer.visionList

    return <Wrapper>
        <UserView>
            <ProfileImage>
                <img src={influencer.profilePic} />
            </ProfileImage>
            <UserInfo>
                <h3>{influencer.username}</h3>
                <CountList>
                    <li>投稿 <span>{util.keta(influencer.allMediaCount)}</span>件</li>
                    <li>フォロワー <span>{util.keta(influencer.follow)}</span>人</li>
                    <li>フォロー <span>{util.keta(influencer.follower)}</span>人</li>
                </CountList>
                <h4>{influencer.fullName}</h4>
                <p>{influencer.biography}</p>
            </UserInfo>
        </UserView>

        {visionList.length > 0 && <AttributesView visionList={visionList} />}

        <MediaWrapper>
            <MediaInfo>
                <h2>投稿</h2>
                <CountList>
                    <li>平均いいね <span>{influencer.avarageLiked}</span>いいね</li>
                    <li>平均コメント数 <span>{influencer.avarageCommented}</span>件</li>
                </CountList>
            </MediaInfo>



            {influencer.timeLineMediaList.map(media => {
                return <MediaView media={media} influencer={influencer} key={media.id} />
            })}
        </MediaWrapper>
    </Wrapper>
}


export default InfluencerView