import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import { influencerStore } from '../../store/InfluencerStore';
import InfluencerView from './InfluencerView'
import { colors } from '../../style'
import store, { AppContext } from '../../store/store'

import { Loader } from '../../util'


const PageTitle = styled.h1`
    font-size:30px;
    margin-bottom:20px;
`

const Wrapper = styled.div`
    padding:20px;
`
const InputWrapper = styled.div`

`

const InputInfo = styled.div`
    display:flex;
    width:100%;
    justify-content:space-between;
    align-items: center;
    height:20px;
    h6{

    }
`

const InputStatus = styled.p`

`

const Input = styled.input`
    border:1px solid ${colors.border};
    border-radius:5px;
    width:100%;
    height:44px;
    padding:0px 10px;
    margin-top:10px;

    &:focus{
        border-color: #999999;
    }

    ${({ valid }) => valid && `
        border-color:  ${colors.valid};
        &:focus{
            border-color: ${colors.valid};
        }
    `}

    ${({ disable }) => disable && `
        pointer-events: none;
        background-color:${colors.disable};
    `}
`

const SearchInfluencerScreen = () => {

    const [influencerUrl, setInfluencerUrl] = useState("https://www.instagram.com/vikana.inc/")
    const validUrl = influencerUrl.startsWith("https://www.instagram.com/")
    const [loading, setLoading] = useState(false)

    const appContext = useContext(AppContext)
    const { appState } = appContext
    const influencer = appState.value.currentInfluencer

    const trySearch = () => {
        if (!validUrl) { return }

        setLoading(true)
        influencerStore.fetchWithUrl(influencerUrl).then((influencer) => {
            store.currentInfluencer = influencer
            setLoading(false)
        })
    }

    return <Wrapper>
        <PageTitle>検索</PageTitle>
        <InputWrapper>
            <InputInfo>
                <h6>InstagramUrl</h6>
                {loading ? <Loader /> : <span />}
            </InputInfo>
            <Input
                type="text"
                valid={validUrl}
                value={influencerUrl}
                disable={loading}
                onChange={(e) => { setInfluencerUrl(e.target.value) }}
                onKeyDown={(e) => {
                    if (e.key !== 'Enter') { return }
                    trySearch()
                }}
            />
        </InputWrapper>
        {influencer && <InfluencerView influencer={influencer} />}

    </Wrapper>
}

export default SearchInfluencerScreen