import { influencerState } from './InfluencerState'

class AppState {

    count = 0
    influencerState = influencerState
    currentInfluencer = undefined

    constructor() {
        console.log("AppState.constructor")
    }

    increment = () => {
        this.count = this.count + 1
    }
}

const appState = new AppState()

export default AppState
export { appState }