import { influencerService } from '../service/InfluencerService'

class InfluencerStore {
    appStateSet = undefined;

    setStateSet = (appStateSet) => {
        this.appStateSet = appStateSet
    }

    fetchWithUrl = (url) => {
        return influencerService.fetchWithUrl(url)
    }
}

const influencerStore = new InfluencerStore()
export { influencerStore }

