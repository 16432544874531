import Media from "../entities/Media";

class MediaService {

    fetchVision = (base64Data) => {
        var KEY = 'AIzaSyCRQDN-TYQ4X60HYVpSesF7Ebacjlb7g9Y'
        var url = 'https://vision.googleapis.com/v1/images:annotate?key='
        var apiUrl = url + KEY

        const planeBase64 = base64Data.split(",")[1]

        const requestData = {
            "requests": [
                {
                    "image": {
                        "content": planeBase64
                    },
                    "features": [
                        {
                            "maxResults": 10,
                            "type": "LABEL_DETECTION"
                        }
                    ]
                }
            ]
        }

        return fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestData)
        }).then(res => {
            return res.json()
        }).then(json => {
            return json.responses[0].labelAnnotations
        })
    }

    fetchVisionWithUrl = (url) => {
        console.log("url", url)
        var KEY = 'AIzaSyCRQDN-TYQ4X60HYVpSesF7Ebacjlb7g9Y'
        var url = 'https://vision.googleapis.com/v1/images:annotate?key='
        var apiUrl = url + KEY

        const requestData = {
            "requests": [
                {
                    "image": {
                        "source": {
                            "imageUri": url
                        }
                    },
                    "features": [
                        {
                            "maxResults": 10,
                            "type": "LABEL_DETECTION"
                        }
                    ]
                }
            ]
        }

        return fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestData)
        }).then(res => {
            return res.json()
        }).then(json => {
            return json.responses[0].labelAnnotations
        })
    }

}

const mediaService = new MediaService()

export { mediaService }

