import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'


const ClientScreen = () => {
    return <div style={{ padding: 20 }}>
        <h2>TODO クライアント</h2>
    </div>
}

export default ClientScreen