class InfluencerState {

    currentInfluencer = undefined

    set influencer(val) {
        this.currentInfluencer = val
    }

}

const influencerState = new InfluencerState()
export { influencerState } 