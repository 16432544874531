import Media from "./Media"

class Influencer {

    constructor(sharedData) {
        this._sharedData = sharedData
        this.timeLineMediaList = this.user.edge_owner_to_timeline_media.edges.slice().map(row => new Media(row))
    }

    get id() {
        return this.user.id
    }

    get user() {
        return this._sharedData.entry_data.ProfilePage[0].graphql.user
    }

    get follow() {
        return this.user.edge_follow.count
    }

    get follower() {
        return this.user.edge_followed_by.count
    }

    get fullName() {
        return this.user.full_name
    }

    get biography() {
        return this.user.biography
    }

    get profilePic() {
        return this.user.profile_pic_url
    }

    get username() {
        return this.user.username
    }

    get allMediaCount() {
        return this.user.edge_owner_to_timeline_media.count
    }

    get avarageLiked() {

        let total = this.timeLineMediaList.map(row => row.liked).reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
        });

        return parseInt(total / this.timeLineMediaList.length)
    }

    get avarageCommented() {

        let total = this.timeLineMediaList.map(row => row.commented).reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
        });

        return parseInt(total / this.timeLineMediaList.length)
    }

    get visionList() {
        if (!this.timeLineMediaList) { return [] }

        let results = []
        this.timeLineMediaList.forEach(media => {
            media.visionList.forEach(vision => {
                let current = results.find(row => {
                    return row.mid == vision.mid
                })
                if (current) {
                    current.count = current.count + 1
                } else {
                    results.push({
                        count: 1,
                        mid: vision.mid,
                        description: vision.description,
                        score: vision.score
                    })
                }
            })
        });

        return results.sort((a, b) => {
            if (b.count > a.count) { return 1 }
            if (a.count > b.count) { return -1 }
            return 0
        })
    }

}


export default Influencer