import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'

const Wrapper = styled.div`
    padding:20px 40px;
    h2{
        font-size:30px;
    }
`

const VisionList = styled.ul`
    margin-top:20px;
    li{
        display:inline-block;
        margin-right:10px;
        line-height:1.4;
        span{
            font-weight:bold;
        }
    }
`

const AttributesView = ({ visionList }) => {
    return <Wrapper>
        <h2>属性</h2>
        <p style={{ marginTop: 10 }}>TODO: ↓ はただの画像解析の集計。ここから属性にマッピングする仕組み。</p>
        <VisionList>
            {visionList.map(vision => <li key={vision.mid}>{vision.description}(<span>{vision.count}</span>)</li>)}
        </VisionList>
    </Wrapper>
}


export default AttributesView