const colors = {
    primary: "#16a085",
    secondary: "#34495e",
    accent: "#e67e22",

    border: "#dddddd",
    valid: "#6666ff",
    disable: "#fafafa"
}

export { colors }