import Influencer from "../entities/Influencer";

class InfluencerService {

    fetchWithUrl = (url) => {
        return fetch(url).then(function (response) {
            return response.text();
        }).then(function (text) {
            // textに文字列で結果が渡される
            // console.log(text)
            const ar = text.split("window._sharedData = ")[1].split(";</script>")
            const sharedData = JSON.parse(ar[0])

            return new Influencer(sharedData)
        });
    }
}

const influencerService = new InfluencerService()

export { influencerService }