class Media {

    constructor(data) {
        this._data = data
        this.visionList = []
    }

    get id() {
        return this.node.id
    }

    get node() {
        return this._data.node
    }

    get liked() {
        return this.node.edge_liked_by.count
    }

    get commented() {
        return this.node.edge_media_to_comment.count
    }

    get isVideo() {
        return this.node.is_video
    }

    get thumbnail_src() {
        return this.node.thumbnail_src
    }

    get caption() {
        return this.node.accessibility_caption
    }

    get takenAtTimestamp() {
        return this.node.taken_at_timestamp
    }

    get takenAtString() {
        let date = new Date(this.takenAtTimestamp * 1000)
        var year_str = date.getFullYear();
        //月だけ+1すること
        var month_str = 1 + date.getMonth();
        var day_str = date.getDate();
        var hour_str = date.getHours();
        var minute_str = date.getMinutes();
        var second_str = date.getSeconds();
        return `${year_str}/${month_str}/${day_str}`
    }

}

export default Media