import styled from 'styled-components'

class util {
  static keta = (num) => {
    return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }

  static imgToBase64 = (img, mime_type) => {
    // New Canvas
    var canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    // Draw Image
    var ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    // To Base64
    return canvas.toDataURL(mime_type);
  }
}

const Loader = styled.div`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #9999ff;
  border-radius: 50%;
  animation: spin 0.75s infinite linear;
  box-sizing: border-box;
  ::before,
  ::after {
    box-sizing: border-box;
    left: -2px;
    top: -2px;
    display: none;
    position: absolute;
    content: '';
    width: inherit;
    height: inherit;
    border: inherit;
    border-radius: inherit;
  }

  border-top-color: transparent;
  ::after {
    display: block;
    left: -2px;
    top: -2px;
    border: inherit;
    transform: rotate(65deg);
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;


export { Loader }
export default util