import React, { createContext, useState } from 'react';
import { appState } from '../state/AppState'
import { influencerStore } from './InfluencerStore'

const initialAppState = { value: appState }

class Store {
    appStateSet = undefined;

    setStateSet = (appStateSet) => {
        this.appStateSet = appStateSet
    }

    increment = () => {
        const [appState, setAppState] = this.appStateSet
        appState.value.increment()
        setAppState({ value: appState.value })
    }

    set currentInfluencer(val) {
        const [appState, setAppState] = this.appStateSet
        appState.value.currentInfluencer = val
        setAppState({ value: appState.value })
    }

    addedVisionList() {
        const [appState, setAppState] = this.appStateSet
        setAppState({ value: appState.value })
    }

}

const store = new Store()

const AppContext = createContext();
const { Provider } = AppContext;
const AppProvider = ({ children }) => {
    const appStateSet = useState(initialAppState)

    store.setStateSet(appStateSet)
    influencerStore.setStateSet(appStateSet)

    const appState = appStateSet[0]

    return <Provider value={{ appState }}>{children}</Provider>
}

export default store
export { AppProvider, AppContext }