import { mediaService } from '../service/MediaService'

class MediaStore {
    appStateSet = undefined;

    setStateSet = (appStateSet) => {
        this.appStateSet = appStateSet
    }

    fetchVision = (base64Data) => {
        return mediaService.fetchVision(base64Data)
    }

    fetchVisionWithUrl = (url) => {
        return mediaService.fetchVisionWithUrl(url)
    }



}

const mediaStore = new MediaStore()
export { mediaStore }

