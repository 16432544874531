import './App.css'
import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import store, { AppContext, AppProvider } from './store/store';
import SearchInfluencerScreen from './screens/influencer/SearchInfluencerScreen'
import ClientScreen from './screens/client/'
import ProjectScreen from './screens/project/'
import styled from 'styled-components'
import Menu from './Menu'
import { colors } from './style'



const Wrapper = styled.div`
  position: relative;
  min-width:100vw;
  min-height:100vh;
`

const ScreenWrapper = styled.div`
  position:relative;
  padding-left: 150px;
  min-height:100vh;
`

const App = () => {

  useEffect(() => {
    console.log("use Effect")
    return () => {
      console.log("clear effect")
    }
  }, [])

  const appContext = useContext(AppContext)

  return <Wrapper>
    <Router>
      <Menu />
      <div>
        <ScreenWrapper>
          <Route path='/search' component={SearchInfluencerScreen} />
          <Route path='/client' component={ClientScreen} />
          <Route path='/project' component={ProjectScreen} />
        </ScreenWrapper>
      </div>
    </Router>
  </Wrapper>

}

export default App;
